import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotesTabFilters } from '../models/notes';
import { CustomerInfo } from '../pages/advances-page/advance-page/components/customer-info-block/models/customer-info-block';
import { TransactionsTabFilter } from '../pages/advances-page/advance-page/models/transactions-tab-filters.model';
import { AdvanceDetails } from '../pages/advances-page/models/advance-page/advance-details.response';
import { TransactionResponseModel } from '../pages/advances-page/models/advance-page/transaction.response';

@Injectable({ providedIn: 'root' })
export class StateManagerService {
  /*********************************************
   * FILTERS
   *********************************************/

  /**
   * Transactions tab filters
   */
  transactionsTabFilters$: BehaviorSubject<{ filters: TransactionsTabFilter }> =
    new BehaviorSubject({ filters: null });

  /**
   * Transactions tab filters
   */
  notesTabFilters$: BehaviorSubject<{ filters: NotesTabFilters }> = new BehaviorSubject({
    filters: null,
  });

  /**
   * Customer info
   */
  applicationDetails$: BehaviorSubject<{ applicationDetails: AdvanceDetails }> =
    new BehaviorSubject({ applicationDetails: null });

  /**
   * Customer info
   */
  customerInfo$: BehaviorSubject<{ customerInfo: CustomerInfo }> = new BehaviorSubject({
    customerInfo: null,
  });

  /**
   * Customer info
   */
  clientId$: BehaviorSubject<{ clientId: number }> = new BehaviorSubject({
    clientId: null,
  });

  /**
   * Notes
   */
  refreshNotes$: Subject<void> = new Subject();

  /*
   * Stored Transactions
   */
  storedTransactions$ = new BehaviorSubject<TransactionResponseModel[]>([]);

  constructor() {}
}
